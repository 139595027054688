import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      return all
        ? [...document.querySelectorAll(el)]
        : document.querySelector(el);
    };

    const backtotop = select(".back-to-top");
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop?.classList.add("active");
      } else {
        backtotop?.classList.remove("active");
      }
    };

    window.addEventListener("scroll", toggleBacktotop);
    window.addEventListener("load", toggleBacktotop);

    return () => {
      window.removeEventListener("scroll", toggleBacktotop);
      window.removeEventListener("load", toggleBacktotop);
    };
  }, []);

  const ensureUrlProtocol = (url) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <footer className="bg-gradient-to-r sm:mt-16 from-slate-900 via-slate-700 to-slate-900 py-10 px-10 font-sans tracking-wide">
      <div className="max-w-2xl mx-auto text-center">
        <a href="javascript:void(0)" className="inline-block">
          <h2 style={{ fontSize: "30px", color: "white", fontWeight: "600" }}>
            Assurance Partners LLC
          </h2>
        </a>
        <p className="text-sm mt-8 text-gray-200">
                  Helping you become one step closer to achieving your financial goals
        </p>

        <ul className="flex items-center justify-center flex-wrap gap-y-3 gap-x-6 mt-8">
          <li>
            <a href="https://www.facebook.com/profile.php?id=61560233856672">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline w-6 h-6"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#1877f2"
                  d="M512 256c0 127.78-93.62 233.69-216 252.89V330h59.65L367 256h-71v-48.02c0-20.25 9.92-39.98 41.72-39.98H370v-63s-29.3-5-57.31-5c-58.47 0-96.69 35.44-96.69 99.6V256h-65v74h65v178.89C93.62 489.69 0 383.78 0 256 0 114.62 114.62 0 256 0s256 114.62 256 256z"
                  aria-label="Facebook"
                />
                <path
                  fill="#fff"
                  d="M355.65 330 367 256h-71v-48.021c0-20.245 9.918-39.979 41.719-39.979H370v-63s-29.296-5-57.305-5C254.219 100 216 135.44 216 199.6V256h-65v74h65v178.889c13.034 2.045 26.392 3.111 40 3.111s26.966-1.066 40-3.111V330z"
                  aria-label="Facebook"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/assurance_partners/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline w-6 h-6"
                viewBox="0 0 24 24"
              >
                <linearGradient
                  id="a"
                  x1="-37.106"
                  x2="-26.555"
                  y1="-72.705"
                  y2="-84.047"
                  gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#fd5" />
                  <stop offset=".5" stopColor="#ff543e" />
                  <stop offset="1" stopColor="#c837ab" />
                </linearGradient>
                <path
                  fill="url(#a)"
                  d="M1.5 1.633C-.386 3.592 0 5.673 0 11.995c0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091C18.89.029 18.778.005 15.91 0 5.737.005 3.507-.448 1.5 1.633z"
                  aria-label="Instagram"
                />
                <path
                  fill="#fff"
                  d="M11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.637-7.728-2.264-2.651-5.87-2.588-8.277-2.588zm6.834 5.797v8.388c-1.271.39-2.645.393-3.993.393s-2.732-.005-3.994-.393v-8.388c1.261-.382 2.645-.395 3.994-.395s2.723.013 3.993.395zm-2.292-1.042c.573 0 1.016.455 1.016 1.031 0 .582-.443 1.031-1.016 1.031s-1.015-.449-1.015-1.031c0-.576.443-1.031 1.015-1.031zm-5.462 1.042c.572 0 1.014.455 1.014 1.031 0 .582-.442 1.031-1.014 1.031-.576 0-1.014-.449-1.014-1.031 0-.576.438-1.031 1.014-1.031z"
                  aria-label="Instagram"
                />
              </svg>
            </a>
          </li>
        </ul>

        <p className="mt-10 text-gray-400 text-xs">
          © 2024 Assurance Partners LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
