import React, { useState } from "react";
import { useSubmitContactMutation } from "../slices/contactApiSlice";

const ContactUs = () => {
  const [submitContact, { isLoading, error }] = useSubmitContactMutation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Clear previous messages
    setErrorMessage(""); // Clear previous messages
    try {
      await submitContact(formData).unwrap();
      setSuccessMessage("Your message has been sent successfully!"); // Set success message
    } catch (err) {
      setErrorMessage("Failed to send your message. Please try again."); // Set error message
    }
  };

  return (
    <div className="font-sans min-h-screen flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div className="max-w-3xl w-full text-center mb-12">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-black mb-4">
          Let's <span className="text-bardum">Connect</span>
        </h1>
        <p className="text-base sm:text-lg text-gray-700">
          Have questions or want to work together? Reach out to us, and we'll be in touch soon!
        </p>
      </div>
      {/* Message Display */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      {/* Contact Form */}
      <div className="w-full max-w-3xl bg-white shadow-lg rounded-xl p-8 sm:p-10 border-t-4 border-red-500">
        <form className="space-y-8" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-semibold text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                required
                onChange={handleChange}
                className="relative w-full p-2 sm:p-3 outline-none bg-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-semibold text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                required
                onChange={handleChange}
                className="relative w-full p-2 sm:p-3 outline-none bg-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              onChange={handleChange}
              className="relative w-full p-2 sm:p-3 outline-none bg-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2"
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-semibold text-gray-700">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              rows="5"
              required
              onChange={handleChange}
              className="relative w-full p-2 sm:p-3 outline-none bg-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2"
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="w-full sm:w-auto py-2 sm:py-3 px-4 sm:px-6 border border-gray-500 text-base sm:text-lg font-medium rounded-lg text-white bg-gradient-to-r from-gray-600 to-red-800 hover:from-bardum hover:to-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 transition-all duration-200"
            >
              Send Message
            </button>
          </div>
          {isLoading && <p>Sending...</p>}
        </form>
      </div>
      {/* Additional Contact Information */}
      <div className="mt-16 max-w-3xl w-full text-center">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-700">
          Contact Details
        </h2>
        <p className="text-bardum mb-8">
          Reach out to us through email or phone, or visit us at our office.
        </p>
        <div className="space-y-4">
          <p className="text-black">
            <span className="font-bold">Email:</span>{" "}
            <a
              href="mailto:ethan@assurancepartnersllc.com"
              className="text-bardum hover:underline transition duration-150"
            >
              ethan@assurancepartnersllc.com
            </a>
          </p>
          <p className="text-black">
            <span className="font-bold">Phone:</span>{" "}
            <a
              href="tel:+1234567890"
              className="text-bardum hover:underline transition duration-150"
            >
              +1 (234) 567-890
            </a>
          </p>
          <p className="text-black">
            <span className="font-bold">Address:</span> 123 Business Street,
            Suite 100, City, Country
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
